import {
  Box,
  Button,
  Flex,
  Heading,
  Link as LinkStandard,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import "./Main.css";
import { CustomConnectButton } from "./CustomConnectButton";
import useIsMobile from "./hooks/useIsMobile";
import Currency from "./Currency";
import { Link } from "react-router-dom";
import useCanBuy from "./hooks/useCanBuy";

function Main() {
  const isMobile = useIsMobile();

  const canBuy = useCanBuy();

  return (
    <VStack width="100%" height="95vh" position="relative">
      <Box
        width="100%"
        display="flex"
        style={{
          zIndex: 9999,
        }}
      >
        <Link to="about">
          <Button variant="primary">ABOUT</Button>
        </Link>
        <Spacer />
        <CustomConnectButton />
      </Box>
      <Heading as="h1" size="4xl" marginBottom="1%">
        Ascension
      </Heading>
      {isMobile && (
        <Text className="content" width="95%">
          Solana, Ethereum and Bitcoin,
          <br /> transposed to the realm of divine perfection.
          <br /> These NFTs are quiet during times of decline,
          <br />
          they become tradeable when rising together. <br />
          (min. 7%, monthly).
        </Text>
      )}
      {!isMobile && (
        <Text className="content" width="80%">
          Solana, Ethereum and Bitcoin, transposed to the realm of divine
          perfection.
          <br />
          These NFTs are quiet during times of decline, they fly and become
          tradeable when rising together. (min. 7%, monthly, together).
        </Text>
      )}
      <Spacer />
      <Flex
        marginTop="5vh"
        direction={isMobile ? "column" : "row"}
        width="80%"
        height={isMobile ? "" : "50vh"}
      >
        <Spacer />
        <Currency
          tokenId={3}
          name="solana"
          period="yearly"
          ticker="SOL"
          canBuy={canBuy}
        />
        <Spacer />
        <Currency
          tokenId={2}
          name="ethereum"
          period="monthly"
          ticker="ETH"
          canBuy={canBuy}
        />
        <Spacer />
        <Currency
          tokenId={1}
          name="bitcoin"
          period="weekly"
          ticker="BTC"
          canBuy={canBuy}
        />
        <Spacer />
      </Flex>
      <Spacer />
      {isMobile && (
        <Text width="95%" className="content">
          <LinkStandard
            href="https://havoc.miragenesi.art"
            textDecor="underline"
          >
            HAVOC
          </LinkStandard>{" "}
          came for the difficult moments.
          <br />
          <strong>Ascension</strong> is here to celebrate the joyful ones.
          <br />A journey through the duality of existence.
        </Text>
      )}
      {!isMobile && (
        <Text width="80%" className="content" marginBottom="-20px">
          <LinkStandard
            href="https://havoc.miragenesi.art"
            textDecor="underline"
          >
            HAVOC
          </LinkStandard>{" "}
          came for the difficult moments. <strong>Ascension</strong> is here to
          celebrate the joyful ones.
          <br />A metaphoric journey through the duality of existence.
        </Text>
      )}
    </VStack>
  );
}

export default Main;
