import { useEffect, useState } from "react";
import { useReadContract } from "wagmi";

const ADDRESSES: Record<"ETH" | "SOL" | "BTC", `0x${string}`> = {
  BTC: "0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c",
  SOL: "0x4ffC43a60e009B551865A93d232E33Fce9f01507",
  ETH: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
};

const useCurrentPrice = ({ ticker }: { ticker: "ETH" | "SOL" | "BTC" }) => {
  const [price, setPrice] = useState<number>();

  const result = useReadContract({
    abi: [
      {
        inputs: [],
        name: "latestAnswer",
        outputs: [
          {
            internalType: "int256",
            name: "answer",
            type: "int256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "latestAnswer",
    address: ADDRESSES[ticker],
    query: {
      refetchInterval: 50000,
    },
  });

  useEffect(() => {
    console.log(result);
    if (result.data !== undefined) {
      setPrice(Number((result.data as bigint) / BigInt(100000000)));
    }
  }, [result]);

  return price;
};

export default useCurrentPrice;
